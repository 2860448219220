import { useState } from "react";
import { FaMagnifyingGlass } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

function SearchInput() {
  const [searchValue, setSearchValue] = useState("");
  const navigate = useNavigate();

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const handleSearch = async () => {
    navigate(`/produtosBusca?search=${encodeURIComponent(searchValue)}`);
    setSearchValue("");
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <InputDiv>
      <input
        type="text"
        id="search-products"
        placeholder="Pesquise o produto que está procurando"
        value={searchValue}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
      />
      <button type="button" onClick={handleSearch}>
        {}
        <FaMagnifyingGlass />
      </button>
    </InputDiv>
  );
}

export default SearchInput;

const InputDiv = styled.div`
  margin-top: 15px;
  align-self: center;
  position: relative;
  width: 400px;
  input {
    width: 100%;
    border-radius: 15px;
    padding: 5px 10px;
    font-size: 14px;
    background-color: var(--input);
    border: solid 1px var(--secondary-color);
    outline-color: var(--secondary-color);
  }
  button {
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: inherit;
    border: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    color: var(--secondary-color);
    font-size: 16px;
    :hover {
      color: var(--primary-color);
      transition: 1s ease;
    }
  }

  @media (max-width: 450px) {
    width: 300px;
  }
`;
