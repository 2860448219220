import {
  FaClock,
  FaEnvelope,
  FaFacebookSquare,
  FaInstagram,
  FaMapMarkerAlt,
  FaPhoneAlt,
  FaWhatsapp,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import styled from "styled-components";

function Footer() {
  return (
    <FooterStyle>
      <div className="phones">
        <a href="tel:+555134762483">
          <p>
            <FaPhoneAlt /> 51 3476-2483
          </p>{" "}
        </a>
        <a href="https://wa.me/5551980169466" target="_blank" rel="noreferrer">
          {" "}
          <p>
            <FaWhatsapp /> Assistência: 51 98016-9466
          </p>{" "}
        </a>
        <a href="https://wa.me/5551999920074" target="_blank" rel="noreferrer">
          {" "}
          <p>
            <FaWhatsapp /> Vendas: 51 99992-0074
          </p>{" "}
        </a>
        <a href="https://wa.me/5551999920601" target="_blank" rel="noreferrer">
          {" "}
          <p>
            <FaWhatsapp /> Metrologia: 51 99992-0601
          </p>{" "}
        </a>
      </div>

      <div className="emails">
        <a href="mailto:vendas@multitecrs.com.br">
          {" "}
          <p>
            {" "}
            <FaEnvelope /> vendas@multitecrs.com.br{" "}
          </p>
        </a>
        <a href="mailto:assistencia@multitecrs.com.br">
          {" "}
          <p>
            {" "}
            <FaEnvelope /> assistencia@multitecrs.com.br{" "}
          </p>{" "}
        </a>
        <a href="mailto:metrologia@multitecrs.com.br">
          {" "}
          <p>
            {" "}
            <FaEnvelope /> metrologia@multitecrs.com.br{" "}
          </p>{" "}
        </a>
      </div>

      <div className="address">
        <a
          href="https://www.google.com/maps/place/R.+Siqueira+Campos,+75+-+Centro,+Canoas+-+RS,+92010-230/@-29.9128037,-51.1810898,17z/data=!3m1!4b1!4m6!3m5!1s0x951970076e7194bf:0xb47113a888d9a932!8m2!3d-29.9128084!4d-51.1785149!16s%2Fg%2F11c27kc_66?entry=ttu"
          target="_blank"
          rel="noreferrer"
        >
          <div className="text-address">
            <FaMapMarkerAlt />
            <div>
              <p>Rua Siqueira Campos, 75</p>
              <p>Canoas/RS - Centro</p>
              <p>CEP: 92.010-230</p>
            </div>
          </div>
        </a>
      </div>

      <div className="hours">
        <p>
          <FaClock />
          Segunda a Quinta das 8h às 18h
        </p>
        <p>
          <FaClock />
          Sextas-feiras das 8h às 17h
        </p>
      </div>

      <div className="social">
        <p>Nos siga nas redes sociais!</p>
        <p>
          <Link
            to="https://www.instagram.com/multitecrs"
            target="_blank"
            rel="noreferrer"
          >
            <FaInstagram />
          </Link>
          <Link
            to="https://facebook.com/profile.php?id=100075703466180"
            target="_blank"
            rel="noreferrer"
          >
            <FaFacebookSquare />
          </Link>
        </p>
      </div>
    </FooterStyle>
  );
}

export default Footer;

const FooterStyle = styled.footer`
  background-color: var(--primary-color);
  color: #ffffff;
  height: 140px;
  display: flex;
  justify-content: space-around;
  padding: 15px 30px;
  font-weight: 300;
  font-size: 14px;
  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  div.phones,
  div.emails,
  div.address {
    gap: 10px;
  }
  div.phones p,
  div.emails p,
  div.address p,
  div.hours p {
    display: flex;
    align-items: center;
    line-height: 20px;
  }
  svg {
    margin-right: 8px;
    font-size: 16px;
  }
  div.address div.text-address {
    flex-direction: row;
  }
  div.address div.text-address svg {
    align-self: flex-start;
    line-height: 20px;
    margin-top: 3px;
  }
  div.social {
    text-align: center;
  }
  div.social p {
    margin: 5px 0;
  }

  @media (max-width: 1044px) {
    flex-wrap: wrap;
    height: 200px;
  }

  @media (max-width: 640px) {
    height: 600px;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 40px 0;
    font-size: 18px;

    div.phones p,
    div.emails p,
    div.address p,
    div.hours p {
      justify-content: center;
      padding: 3px 0;
    }

    svg {
      font-size: 20px;
    }
  }
`;
