import styled from "styled-components";

export const FormContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 400px;
  gap: 10px;

  input {
    border: 1px solid var(--secondary-color);
    border-radius: 20px;
    padding: 10px 20px;
    font-size: 14px;
    background-color: var(--input);
    outline-color: var(--secondary-color);
  }

  button {
    margin-top: 10px;
    width: 100px;
    padding: 8px;
    border-radius: 15px;
    border: solid 1px #000000;
    background-color: var(--text-highlight);
    color: #ffffff;
    font-weight: 300;
    letter-spacing: 1px;
    cursor: pointer;
  }

  button:hover {
    font-weight: 500;
  }

  @media (max-width: 576px) {
    width: 300px;
    align-items: center;

    input {
      width: 100%;
    }
  }
`;

export const TextArea = styled.textarea`
  font-size: 15px;
  height: 100px;
  resize: none;
  border: 1px solid var(--secondary-color);
  outline-color: var(--secondary-color);
  border-radius: 15px;
  padding: 10px 20px;
  background-color: var(--input);
  margin-top: 10px;

  @media (max-width: 576px) {
    width: 100%;
  }
`;
