import emailjs from "@emailjs/browser";
import { useState } from "react";
import { FaClock, FaEnvelope, FaPhoneAlt, FaWhatsapp } from "react-icons/fa";
import styled from "styled-components";
import { Form, FormContainer, TextArea } from "../../style/form-style";
import Title from "../title";

function Contact() {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const sendEmail = (e: any) => {
    e.preventDefault();
    setIsSubmitting(true);

    emailjs
      .sendForm(
        "service_7wxpdik",
        "template_8bqvv7p",
        e.target,
        "2OBJNEWjKZnd-wEBx",
      )
      .then(
        (result): void => {
          e.target.reset();

          console.log("Email successfully sent!", result);
          alert("Email enviado com sucesso!");
        },
        (error) => {
          console.log("Failed to send email:", error);
          alert(
            "Falha ao enviar o email. Por favor, tente novamente ou entre em contato por telefone.",
          );
        },
      )
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <section>
      <Title title="contato" />
      <Container>
        <Infos>
          <p>
            Bem-vindo à Multitec - sua parceira confiável em equipamentos para
            laboratório e assistência técnica! Estamos aqui para fornecer
            soluções personalizadas que atendam às suas necessidades. Se você
            tiver alguma dúvida, precisar de um orçamento ou assistência
            técnica, não hesite em entrar em contato conosco. Sua satisfação é a
            nossa prioridade.
          </p>

          <ContactLinks>
            <p>
              <a href="tel:+555134762483">
                <FaPhoneAlt /> 51 3476-2483
              </a>
            </p>
            <p>
              <a
                href="https://wa.me/5551980169466"
                target="_blank"
                rel="noreferrer"
              >
                <FaWhatsapp /> Assistência: 51 98016-9466
              </a>
            </p>
            <p>
              <a
                href="https://wa.me/5551999920074"
                target="_blank"
                rel="noreferrer"
              >
                <FaWhatsapp /> Vendas: 51 99992-0074
              </a>
            </p>
            <p>
              <a
                href="https://wa.me/5551999920601"
                target="_blank"
                rel="noreferrer"
              >
                <FaWhatsapp /> Metrologia: 51 99992-0601
              </a>
            </p>
            <p>
              <a href="mailto:vendas@multitecrs.com.br">
                <FaEnvelope /> vendas@multitecrs.com.br
              </a>
            </p>
            <p>
              <a href="mailto:assistencia@multitecrs.com.br">
                <FaEnvelope /> assistencia@multitecrs.com.br
              </a>
            </p>
            <p>
              <a href="mailto:metrologia@multitecrs.com.br">
                <FaEnvelope /> metrologia@multitecrs.com.br
              </a>
            </p>
            <p>
              <FaClock /> Segunda a Quinta das 8h às 18h
            </p>
            <p>
              <FaClock /> Sextas-feiras das 8h às 17h
            </p>
          </ContactLinks>

          <p>
            Agradecemos por escolher a Multitec como sua parceira em soluções
            laboratoriais. Estamos ansiosos para atendê-lo e ajudá-lo a alcançar
            seus objetivos laboratoriais!
          </p>
        </Infos>

        <FormContainer>
          <Form onSubmit={sendEmail}>
            <label htmlFor="name">{}</label>
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Nome:"
              required
            />

            <label htmlFor="email">{}</label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="E-mail:"
              required
            />

            <label htmlFor="phone">{}</label>
            <input
              type="tel"
              id="phone"
              name="phone"
              placeholder="Telefone:"
              required
              pattern="\d{10,11}"
              title="O telefone deve ter 10 ou 11 dígitos."
            />

            <label htmlFor="company">{}</label>
            <input
              type="text"
              id="company"
              name="company"
              placeholder="Empresa:"
              required
            />

            <label htmlFor="subject">{}</label>
            <input
              type="text"
              id="subject"
              name="subject"
              placeholder="Assunto:"
              required
            />

            <TextArea name="message" id="mensagem" placeholder="Mensagem:" />

            <button type="submit" disabled={isSubmitting}>
              {isSubmitting ? "Enviando..." : "Enviar"}
            </button>
          </Form>
        </FormContainer>
      </Container>
    </section>
  );
}

export default Contact;

const Container = styled.div`
  margin: 20px 50px;
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
`;

const Infos = styled.div`
  text-align: justify;
  line-height: 25px;
`;

const ContactLinks = styled.div`
  margin: 20px 0;
  color: var(--text-highlight);
  line-height: 30px;

  :hover {
    font-weight: 600;
    transition: 0.5s ease;
  }
`;
