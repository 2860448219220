import { useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import logo from "../../assets/logo_multitec.png";
import CartProductsCounter from "../cart-products-counter";
import MegaMenu from "../mega-menu";

function Header() {
  const [isProductsMenuOpen, setProductsMenuOpen] = useState(false);
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const timeoutRef = useRef<number>();

  const handleMouseEnter = (menuName: string) => {
    if (menuName === "produtos") {
      clearTimeout(timeoutRef.current);
      setProductsMenuOpen(true);
    }
  };

  const handleMouseLeave = () => {
    timeoutRef.current = window.setTimeout(() => {
      setProductsMenuOpen(false);
    }, 500);
  };

  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
  };

  return (
    <HeaderStyle>
      <a href="/">
        <Img src={logo} alt="logotipo multitec" />
      </a>

      <MobileMenuIcon onClick={toggleMobileMenu}>
        <div className={isMobileMenuOpen ? "open" : ""}>
          <span />
          <span />
          <span />
        </div>
      </MobileMenuIcon>

      <MenuContainer isOpen={isMobileMenuOpen}>
        <Menu>
          <li>
            <NavLink to="/" className="menu" onClick={closeMobileMenu}>
              Home
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/institucional"
              className="menu"
              onClick={closeMobileMenu}
            >
              A Multitec
            </NavLink>
          </li>
          <li
            onMouseEnter={() => handleMouseEnter("produtos")}
            onMouseLeave={handleMouseLeave}
          >
            <NavLink to="/produtos" className="menu" onClick={closeMobileMenu}>
              Produtos
            </NavLink>
            {isProductsMenuOpen && (
              <MegaMenu
                isOpen={isProductsMenuOpen}
                onClickHandler={() => setMobileMenuOpen(false)}
              />
            )}
          </li>
          <li>
            <NavLink to="/servicos" className="menu" onClick={closeMobileMenu}>
              Serviços
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/orcamento"
              className="menu"
              onClick={() => setMobileMenuOpen(false)}
            >
              Orçamento
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/contato"
              className="menu"
              onClick={() => setMobileMenuOpen(false)}
            >
              Contato
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/acesso-restrito"
              className="menu"
              onClick={() => setMobileMenuOpen(false)}
            >
              Acesso Restrito
            </NavLink>
          </li>
        </Menu>
      </MenuContainer>
      <CartProductsCounter />
    </HeaderStyle>
  );
}

export default Header;

const HeaderStyle = styled.header`
  height: 90px;
  width: 100%;
  background-color: var(--secondary-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 20px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;

  @media (max-width: 1060px) {
    font-size: 14px;
  }
`;

const Img = styled.img`
  max-width: 280px;
  margin: 0 10px;

  @media (max-width: 576px) {
    max-width: 200px;
  }
  @media (max-width: 370px) {
    max-width: 150px;
  }
`;

const MenuContainer = styled.div<{ isOpen: boolean }>`
  display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
  position: absolute;
  top: 60px;
  left: 0;
  width: 100%;
  flex-direction: column;
  z-index: 100;

  @media (min-width: 960px) {
    display: flex;
    justify-content: center;
    align-items: center;
    position: static;
  }
`;

const Menu = styled.ul`
  display: flex;
  align-items: center;

  li {
    height: 100%;
  }

  .menu {
    padding: 4px 10px;
    border-radius: 15px;
  }

  .menu:hover {
    background-color: var(--hover);
    transition: 0.5s ease-in-out;
  }

  @media (max-width: 960px) {
    background-color: var(--background-card);
    border-radius: 3px;
    box-shadow: 1px 1px 10px;
    width: 60vw;
    align-self: flex-end;
    margin-right: 5px;
    flex-direction: column;
    line-height: 40px;
    font-size: 16px;
    padding: 15px;
  }
`;

const MobileMenuIcon = styled.div`
  display: none;

  @media (max-width: 960px) {
    display: flex;
    cursor: pointer;
    position: relative;
    height: 24px;
    width: 24px;

    div {
      position: absolute;
      top: 30%;
      transform: translate(-50%, -50%);
      width: 25px;
      height: 2px;
      transition: transform 0.3s ease-in-out;

      span {
        display: block;
        width: 100%;
        height: 2px;
        background-color: var(--background-button);
        margin-bottom: 4px;
        transition: transform 0.3s ease-in-out;
      }

      &.open {
        transform: translate(-50%, -50%) rotate(-45deg);

        span {
          &:first-child {
            transform: rotate(90deg) translate(7px);
          }
          &:last-child {
            display: none;
          }
        }
      }
    }
  }
`;
