import emailjs from "@emailjs/browser";
import { useState } from "react";
import { FaTrashAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Product } from "../../models/product";
import { RootState } from "../../store";
import {
  ProductToAdd,
  addProductToCart,
  clearProductsFromCart,
  decreaseQuantity,
  removeProductFromCart,
} from "../../store/reducers/cartReducers";
import { Form, FormContainer, TextArea } from "../../style/form-style";
import Title from "../title";

function Budget() {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const dispatch = useDispatch();
  const cartProducts = useSelector(
    (state: RootState) => state.cart.cartProducts,
  );

  const handleIncreaseQuantity = (productId: number) => {
    const productToAdd: ProductToAdd = {
      id: productId,
    };
    dispatch(addProductToCart({ product: productToAdd }));
  };

  const handleDecreaseQuantity = (productId: number) => {
    dispatch(decreaseQuantity(productId));
  };

  const handleRemoveProduct = (productId: number) => {
    dispatch(removeProductFromCart(productId));
  };

  const sendEmail = (e: any) => {
    e.preventDefault();
    setIsSubmitting(true);

    let productsMessage = "";
    cartProducts.forEach((product) => {
      productsMessage += `<p><strong>Produto:</strong> ${product.name}\n`;
      productsMessage += "<br>\n";
      productsMessage += `<strong>Quantidade:</strong> ${product.quantity}\n</p>`;
    });

    const templateParams = {
      name: e.target.name.value,
      email: e.target.email.value,
      phone: e.target.phone.value,
      company: e.target.company.value,
      cnpj_cpf: e.target["cnpj-cpf"].value,
      message: e.target.message.value,
      productsMessage,
    };

    emailjs
      .send(
        "service_7wxpdik",
        "template_60494nq",
        templateParams,
        "2OBJNEWjKZnd-wEBx",
      )
      .then(
        (result): void => {
          e.target.reset();
          dispatch(clearProductsFromCart());

          console.log("Email successfully sent!", result);
          alert("Email enviado com sucesso!");
        },
        (error) => {
          console.log("Failed to send email:", error);
          alert(
            "Falha ao enviar o email. Por favor, tente novamente ou entre em contato por telefone.",
          );
        },
      )
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <section>
      <Title title="orçamento" />
      <Container>
        <Products>
          {cartProducts.length === 0 ? (
            <>
              <p>Nenhum produto adicionado para orçamento</p>
              <ProductsLink to="/produtos">Ver produtos</ProductsLink>
            </>
          ) : (
            <>
              {cartProducts.map((product: Product) => (
                <ProductCard>
                  <ImgContainer>
                    <img src={product.image} alt="foto do produto" />
                  </ImgContainer>
                  <ProductInfo>
                    <Link to={`/produtos/${product.id}`}>
                      <p className="model">{product.name}</p>
                    </Link>
                    <QuantityContainer>
                      <p className="quantity">quantidade</p>
                      <div className="quantity-info">
                        <button
                          type="button"
                          onClick={() => handleDecreaseQuantity(product.id)}
                        >
                          -
                        </button>
                        <span>{product.quantity}</span>
                        <button
                          type="button"
                          onClick={() => handleIncreaseQuantity(product.id)}
                        >
                          +
                        </button>
                        <button
                          type="button"
                          onClick={() => handleRemoveProduct(product.id)}
                        >
                          {}
                          <FaTrashAlt />
                        </button>
                      </div>
                    </QuantityContainer>
                  </ProductInfo>
                </ProductCard>
              ))}
            </>
          )}
        </Products>

        <FormContainer>
          <Form onSubmit={sendEmail}>
            <label htmlFor="username">{}</label>
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Nome:"
              required
            />

            <label htmlFor="email">{}</label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="E-mail:"
              required
            />

            <label htmlFor="phone">{}</label>
            <input
              type="tel"
              id="phone"
              name="phone"
              placeholder="Telefone:"
              required
              pattern="\d{10,11}"
              title="O telefone deve ter 10 ou 11 dígitos."
            />

            <label htmlFor="company">{}</label>
            <input
              type="text"
              id="company"
              name="company"
              placeholder="Empresa:"
              required
            />

            <label htmlFor="cnpj-cpf">{}</label>
            <input
              type="text"
              id="cnpj-cpf"
              name="cnpj-cpf"
              placeholder="CNPJ / CPF:"
              required
              pattern="\d{11}|\d{14}"
              title="CPF deve ter 11 dígitos e CNPJ deve ter 14 dígitos."
            />

            <TextArea name="message" id="mensagem" placeholder="Mensagem:" />

            <button type="submit" disabled={isSubmitting}>
              {isSubmitting ? "Enviando..." : "Enviar"}
            </button>
          </Form>
        </FormContainer>
      </Container>
    </section>
  );
}

export default Budget;

const Container = styled.div`
  margin: 20px 50px;
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media (max-width: 850px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
  }
`;

const ProductsLink = styled(Link)`
  background-color: var(--text-highlight);
  color: #ffffff;
  padding: 8px;
  border-radius: 10px;
  font-weight: 300;
  font-size: 12px;
  text-align: center;
  width: 100px;
  margin: 20px 0;

  &:hover {
    scale: 1.05;
    transition: 0.2s ease-in-out;
  }
`;

const Products = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media (max-width: 768px) {
    align-items: center;
    text-align: center;
  }
`;

const ProductCard = styled.div`
  background-color: #ffffff;
  border: solid 1px var(--primary-color);
  border-radius: 8px;
  display: flex;
  width: 350px;
  height: 120px;
  display: flex;
  margin-right: 15px;

  a {
    text-decoration: underline;
  }

  @media (max-width: 768px) {
    margin: 0;
  }
  @media (max-width: 576px) {
    width: 280px;
  }
`;

const ImgContainer = styled.div`
  padding: 2px;
  border-radius: 10px;
  text-align: center;
  flex: 1;

  img {
    width: 90%;
    height: 100%;
    align-self: center;
    border-radius: 10px;
    object-fit: cover;
  }
`;

const ProductInfo = styled.div`
  flex: 1.5;
  text-align: center;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .model {
    padding-top: 10px;
    letter-spacing: 1px;
    font-weight: 500;
  }

  .model::first-letter {
    text-transform: uppercase;
  }
`;

const QuantityContainer = styled.div`
  .quantity {
    text-transform: capitalize;
    font-size: 14px;
    letter-spacing: 1px;
    padding: 3px;
  }

  .quantity-info {
    font-size: 14px;
    display: flex;
    justify-content: center;
    gap: 5px;

    span {
      width: 18px;
      height: 18px;
      display: inline-block;
      border: solid 1px var(--secondary-color);
      line-height: 18px;
    }

    button {
      cursor: pointer;
      border: solid 1px var(--secondary-color);
      width: 18px;
      height: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    svg {
      font-size: 13px;
      color: var(--primary-color);
    }
  }
`;
