import { BrowserRouter, Route, Routes } from "react-router-dom";

import Footer from "../components/footer";
import Header from "../components/header";
import SearchInput from "../components/search-input";
import BudgetPage from "../pages/budget-page";
import CategoryPage from "../pages/category-page";
import ContactPage from "../pages/contact";
import HomePage from "../pages/home-page";
import Institutional from "../pages/institutional-page";
import NewsInfosPage from "../pages/news-info-page";
import NewsPage from "../pages/news-page";
import Product from "../pages/products-details-page";
import ProductsPage from "../pages/products-page";
import ProductsSearchPage from "../pages/products-search-page";
import RestrictedAccessPage from "../pages/restricted-access";
import ServicesPage from "../pages/services-page";
import { AppContainer, ContentContainer } from "../style/global-style";

function AppRoutes() {
  return (
    <BrowserRouter>
      <AppContainer>
        <Header />
        <ContentContainer>
          <SearchInput />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/institucional" element={<Institutional />} />
            <Route path="/produtos" element={<ProductsPage />} />
            <Route path="/produtosBusca" element={<ProductsSearchPage />} />
            <Route path="/produtos/:id" element={<Product />} />
            <Route path="/servicos" element={<ServicesPage />} />
            <Route path="/orcamento" element={<BudgetPage />} />
            <Route path="/contato" element={<ContactPage />} />
            <Route path="/acesso-restrito" element={<RestrictedAccessPage />} />
            <Route path="/noticias" element={<NewsPage />} />
            <Route path="/noticias/:id" element={<NewsInfosPage />} />
            <Route path="/:category" element={<CategoryPage />} />
          </Routes>
        </ContentContainer>
        <Footer />
      </AppContainer>
    </BrowserRouter>
  );
}

export default AppRoutes;
