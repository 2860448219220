import { useEffect, useRef, useState } from "react";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";
import { Link } from "react-router-dom";
import styled from "styled-components";

interface ImageObject {
  src: string;
  path: string;
}

interface BannerProps {
  images: ImageObject[];
  autoChangeInterval: number;
}

function Banner({ images, autoChangeInterval }: BannerProps) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const intervalIdRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    const changeImage = () => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    intervalIdRef.current = setInterval(changeImage, autoChangeInterval);

    return () => {
      if (intervalIdRef.current) clearInterval(intervalIdRef.current);
    };
  }, [images, autoChangeInterval]);

  const handlePrevClick = () => {
    if (intervalIdRef.current) clearInterval(intervalIdRef.current);
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length,
    );

    intervalIdRef.current = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, autoChangeInterval);
  };

  const handleNextClick = () => {
    if (intervalIdRef.current) clearInterval(intervalIdRef.current);
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);

    intervalIdRef.current = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, autoChangeInterval);
  };

  return (
    <BannerDiv>
      <Link to={images[currentIndex].path} target="_blank">
        <img src={images[currentIndex].src} alt={`${currentIndex + 1}`} />
      </Link>
      <button type="button" className="arrow left" onClick={handlePrevClick}>
        {}
        <FaAngleLeft />
      </button>
      <button type="button" className="arrow right" onClick={handleNextClick}>
        {}
        <FaAngleRight />
      </button>
    </BannerDiv>
  );
}

export default Banner;

const BannerDiv = styled.div`
  position: relative;
  overflow: hidden;
  margin-top: 20px;

  img {
    width: 100%;
    height: auto;
  }

  .arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    color: var(--primary-color);
    font-size: 60px;

    &:hover {
      opacity: 0.8;
    }
  }

  .left {
    left: -10px;
  }

  .right {
    right: -10px;
  }

  @media (max-width: 768px) {
    .arrow {
      font-size: 30px;
    }
    .left {
      left: -5px;
    }

    .right {
      right: -5px;
    }
  }
`;
